<template>
    <div class="wrapper">
        <router-view name="contract_container" ref="contract-container"/>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
    name: "Container",
    data() {
		return {
		}
	},
    computed: {
		...mapGetters('helper', ['labels']),
		saveBtnDisabled() {
			return this.$store.getters['contract/countContentChanges'].saveBtnDisabled;
		},
		localData() {
			return this.createContractData();
		},
		pageHeaderData() {
			let output = {
				preTitle: this.labels.contract_tiree,
				title: this.localData.title,
				btnList: [
                    {
						type: 'label',
						link: { name: 'signatories', params: { kind: 'signatories' } },
						label: this.labels.signatories,
						class: `btn-type-label ${ this.$route.name === 'signatories' ? 'current' : '' }`
					},
					{
						type: 'label',
						link: { name: 'contractual-parties', params: { kind: 'contractual-parties' } },
						label: this.labels.contractual_parties,
						class: `btn-type-label ${ this.$route.name === 'contractual-parties' ? 'current' : '' }`
					},
					{
						type: 'label',
						link: { name: 'contract-content' },
						label: this.labels.essentiala_negotii,
						class: `btn-type-label ${ this.$route.name === 'contract-content' ? 'current' : '' }`
					},
					{
						type: 'button',
						label: this.labels.save,
						variant: 'btn-green',
						class: 'btn-type-button',
						action: this.saveAction,
						disabled: this.saveBtnDisabled
					}
				]
			}
			return output;
		}
	},
    methods: {
        ...mapMutations( 'helper', {
            setPageHeaderData: 'setPageHeaderData',
        } ),
        ...mapActions("notification", {
            addNotification: "addNotification"
        }),
        ...mapActions("contract", {
            getSingle: "getSingle"
        }),
        ...mapGetters("contract", {
            createContractData: "createContractData"
        }),
		...mapMutations('contract', ['clearContractData']),
        getSingleData() {
			this.getSingle( { id: this.$route.params.id } )
			.then( res => {
				if (res.success) {
					this.loopKey = Date.now();
				} else if (res.error) {
					this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
				}
			})
			.catch( err => console.log( err ) );
		},
        saveAction() {
			let actionName = '';
			
			if (this.$route.name === 'contractual-parties') actionName = 'saveData';

			if (this.$route.name === 'contract-content') actionName = 'saveContractContent';

			this.$refs['contract-container'][actionName]();
		},
    },
    beforeMount() {
		this.setPageHeaderData( { preTitle: '', title: '', btnList: [] } );
	},
	mounted() {
        this.getSingleData();
		this.setPageHeaderData(this.pageHeaderData);
	},
	beforeDestroy() {
		this.setPageHeaderData({});
	},
	watch: {
		pageHeaderData( value ) {
			this.setPageHeaderData( value );
		},
	},
	beforeRouteLeave (to, from, next) {
        this.clearContractData();

        next();
	},
}
</script>

<style lang="scss" scoped>
</style>